import { ChangeDetectionStrategy, Component, output, ViewEncapsulation } from '@angular/core';
import { AssetPipe } from '@libraries/asset/asset.pipe';
import { ButtonComponent } from '@shared/button/button.component';
import { ButtonType } from '@shared/button/button.enums';
import { HeaderImageComponent } from '@shared/header-image/header-image.component';
import { SectionWrapperComponent } from '@shared/section-wrapper/section-wrapper.component';
import { CareerAssetPath } from '../career.path';
import { SharedAssetPath } from '@shared/asset/asset.path';
import { NgOptimizedImage } from '@angular/common';

@Component({
    selector: 'jsdaddy-career-header',
    standalone: true,
    imports: [
        AssetPipe,
        SectionWrapperComponent,
        HeaderImageComponent,
        ButtonComponent,
        NgOptimizedImage,
    ],
    templateUrl: './career-header.component.html',
    styleUrl: './career-header.component.scss',
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CareerHeaderComponent {
    public assetPath = CareerAssetPath.HEADER;
    public assetPathShared = SharedAssetPath.ROOT;

    public readonly buttonTypeYellow = ButtonType.PrimaryYellow;

    public seeJobOpenings = output<void>();

    public onSeeJobOpeningsClick() {
        this.seeJobOpenings.emit();
    }
}
