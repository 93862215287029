<section class="row pos-rel">
    <jsdaddy-section-wrapper class="pos-abs" overflowXStyle="inherit" overflowYStyle="inherit">
        <div class="row">
            <jsdaddy-header-title
                title="Life at JS Daddy"
                subtitle="AMAZING TEAM"
                [needStar]="true" /></div
    ></jsdaddy-section-wrapper>
    <div class="row bg ai-end" [style.background-image]="'bg' | asset: assetPath : true | imageUrl">
        <ng-container
            *ngTemplateOutlet="
                img;
                context: { $implicit: 4, additionalStyleClass: 'hide-desktop hide-mobile' }
            " />
        <div class="column hide-desktop hide-mobile tablet-second">
            <ng-container *ngTemplateOutlet="img; context: { $implicit: 2 }" />
            <ng-container *ngTemplateOutlet="img; context: { $implicit: 1 }" />
        </div>
        <div class="column hide-desktop hide-mobile tablet-last">
            <ng-container *ngTemplateOutlet="img; context: { $implicit: 3 }" />
            <div class="row">
                <ng-container *ngTemplateOutlet="img; context: { $implicit: 5 }" />
                <div class="column last-column">
                    <ng-container *ngTemplateOutlet="img; context: { $implicit: 6 }" />
                    <ng-container *ngTemplateOutlet="img; context: { $implicit: 7 }" />
                </div>
            </div>
        </div>
        <div class="column mobile-mosaic hide-tablet hide-desktop">
            <div class="row mobile-mosaic-row">
                <div class="column mobile-mosaic-first jc-fe">
                    <ng-container *ngTemplateOutlet="img; context: { $implicit: 6 }" />
                    <ng-container *ngTemplateOutlet="img; context: { $implicit: 3 }" />
                </div>
                <div class="column mobile-mosaic-second">
                    <ng-container *ngTemplateOutlet="img; context: { $implicit: 7 }" />
                    <ng-container *ngTemplateOutlet="img; context: { $implicit: 5 }" />
                </div>
            </div>
            <div class="row">
                <ng-container *ngTemplateOutlet="img; context: { $implicit: 2 }" />
                <ng-container *ngTemplateOutlet="img; context: { $implicit: 1 }" />
            </div>
            <ng-container *ngTemplateOutlet="img; context: { $implicit: 4 }" />
        </div>
    </div>
    <div class="column images hide-tablet hide-mobile">
        <div class="row">
            <ng-container *ngTemplateOutlet="img; context: { $implicit: 1 }" />
            <ng-container *ngTemplateOutlet="img; context: { $implicit: 2 }" />
            <ng-container *ngTemplateOutlet="img; context: { $implicit: 3 }" />
        </div>
        <div class="row">
            <ng-container *ngTemplateOutlet="img; context: { $implicit: 4 }" />
            <ng-container *ngTemplateOutlet="img; context: { $implicit: 5 }" />
            <div class="column two-images">
                <ng-container *ngTemplateOutlet="img; context: { $implicit: 6 }" />
                <ng-container *ngTemplateOutlet="img; context: { $implicit: 7 }" />
            </div>
        </div>
    </div>
</section>

<ng-template #img let-number let-additionalStyleClass="additionalStyleClass">
    <img
        [alt]="'img' + number"
        [class]="[additionalStyleClass, 'img img-' + number].join(' ')"
        [ngSrc]="number | asset: assetPath : true"
        width="100"
        height="100" />
</ng-template>
