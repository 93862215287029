import { isPlatformServer } from '@angular/common';
// type-coverage:ignore-next-line
import { inject, Injectable, makeStateKey, PLATFORM_ID } from '@angular/core';
import { SharedAssetPath } from '@shared/asset/asset.path';
import { BaseHttpService } from '@libraries/base-http/base-http.service';
import { concatMap, filter, from, map, Observable, of, switchMap, toArray } from 'rxjs';
import { HomeAssetPath } from '../home.path';
import { IOpenSourceMask, IOpenSourceProject, IOpenSourceProps } from './open-source.interface';

@Injectable()
export class OpenSourceService {
    public http = inject(BaseHttpService);
    public date: Date = new Date();
    private readonly platformId = inject<string>(PLATFORM_ID);

    public readonly projects: IOpenSourceProject[] = [
        {
            title: 'Ngx Loader Indicator',
            text: 'Awesome loader for Angular applications. No wrappers only your elements',
            img: 'fingerprint',
            url: 'ngx-loader-indicator',
            rate: 0,
        },
        {
            title: 'Ngx Copypaste',
            text: 'A pure and awesome copy paste directive for Angular',
            img: 'hand',
            url: 'ngx-copypaste',
            rate: 0,
        },
    ];

    public readonly ngxMaskProps: IOpenSourceMask[] = [
        {
            icon: `${HomeAssetPath.OPEN_SOURCE}/depends`,
            value: 155,
            text: 'Packages depends',
            path: '',
            key: '',
        },
        {
            icon: `${SharedAssetPath.ROOT}/github`,
            value: 0,
            text: 'github stars',
            path: 'https://api.github.com/repos/JsDaddy/ngx-mask',
            key: 'stargazers_count',
        },
        {
            icon: `${HomeAssetPath.OPEN_SOURCE}/download-per-month`,
            value: 0,
            text: 'downloads per month',
            path: `https://api.npmjs.org/downloads/point/last-month/ngx-mask`,
            key: 'downloads',
        },
        {
            icon: `${HomeAssetPath.OPEN_SOURCE}/downloads`,
            value: 0,
            text: 'downloads',
            path: `https://api.npmjs.org/downloads/point/2017-01-01:${this.date.getFullYear()}-${this.setNullToDate(
                this.date.getMonth() + 1
            )}-${this.setNullToDate(this.date.getDate())}/ngx-mask`,
            key: 'downloads',
        },
    ];

    public getPropsList(): Observable<IOpenSourceMask[]> {
        if (isPlatformServer(this.platformId)) {
            return of(this.ngxMaskProps);
        }
        return from(this.ngxMaskProps).pipe(
            concatMap((prop: IOpenSourceMask) => {
                if (!prop.path) {
                    return of(prop);
                }
                return this.http
                    .getData<IOpenSourceProps>(
                        prop.path,
                        { stargazers_count: 0, downloads: 0 },
                        makeStateKey<IOpenSourceProps>(prop.text)
                    )
                    .pipe(
                        switchMap((data) => {
                            // type-coverage:ignore-next-line
                            return of(data[prop.key as keyof IOpenSourceProps]).pipe(
                                map((value) => ({ ...prop, value }))
                            );
                        })
                    );
            }),
            toArray()
        );
    }

    private setNullToDate(date: number): number | string {
        if (date < 10) {
            return `0${date}`;
        }
        return date;
    }

    public getProjects(): Observable<IOpenSourceProject[]> {
        if (isPlatformServer(this.platformId)) {
            return of(this.projects);
        }
        return from(this.projects).pipe(
            concatMap((project: IOpenSourceProject) => {
                return this.http
                    .getData<{
                        stargazers_count: number;
                    }>(
                        `https://api.github.com/repos/JsDaddy/${project.url}`,
                        { stargazers_count: 0 },
                        makeStateKey<{ stargazers_count: number }>(project.title)
                    )
                    .pipe(
                        filter(Boolean),
                        map(({ stargazers_count }) => {
                            project.rate = stargazers_count;
                            return project;
                        })
                    );
            }),
            toArray()
        );
    }
}
