import { inject, Pipe, PipeTransform } from '@angular/core';
import { ScreenSize } from '@shared/screen-size/screen-size.enum';
import { MenuService } from './menu.service';

@Pipe({
    name: 'rightServicePage',
    standalone: true,
})
export class RightServicePagePipe implements PipeTransform {
    public readonly menuService = inject(MenuService);

    public transform(value: number | null, isShowServiceInfo: boolean, innerWidth: number): number {
        let additionalMargin = 42;
        if (innerWidth > ScreenSize.DESKTOP) additionalMargin = 84;
        if (innerWidth <= ScreenSize.MOBILE) additionalMargin = 21;
        return isShowServiceInfo
            ? -(innerWidth - Number(value)) / 2 - additionalMargin
            : -this.menuService.getWidthServicePage(value, innerWidth) -
                  (innerWidth - Number(value)) / 2 -
                  14;
    }
}
