import { isPlatformServer } from '@angular/common';
import { HttpClient } from '@angular/common/http';
// type-coverage:ignore-next-line
import { inject, Injectable, PLATFORM_ID } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { FormSendingDialogService } from '@shared/form-sending-dialog/form-sending-dialog.service';
import { BehaviorSubject, catchError, EMPTY, tap } from 'rxjs';
import { IContactFormItem } from './contact-form.interfaces';

@Injectable()
export class ContactFormService {
    private readonly http = inject(HttpClient);
    private readonly platformId = inject<string>(PLATFORM_ID);
    private readonly currentStep$$ = new BehaviorSubject<number>(0);

    public readonly currentStep$ = this.currentStep$$.asObservable();

    public readonly nameValidators = [Validators.required];
    public readonly emailValidators = [Validators.required, Validators.email];
    public readonly messageValidators = [Validators.required, Validators.minLength(5)];

    private readonly fb = inject(FormBuilder);
    private readonly formSendingDialogService = inject(FormSendingDialogService);

    public form = this.fb.group({
        name: [null, this.nameValidators],
        email: [null, this.emailValidators],
        shortMessage: [null, this.messageValidators],
    });

    public next() {
        const currentStep = this.currentStep$$.getValue();
        if (currentStep === this.steps.length - 1) {
            if (this.form.valid) {
                this.sendEmail();
            }
            return;
        }
        this.currentStep$$?.next(currentStep + 1);
    }

    public back() {
        const currentStep = this.currentStep$$.getValue();
        this.currentStep$$?.next(currentStep - 1);
    }

    public readonly steps: IContactFormItem[] = [
        {
            formControlName: 'name',
            validators: this.nameValidators,
            placeholder: 'Name & Surname',
        },
        {
            formControlName: 'email',
            validators: this.emailValidators,
            placeholder: 'Email',
        },
        {
            formControlName: 'shortMessage',
            validators: this.messageValidators,
            placeholder: 'Short Message',
            isTextarea: true,
        },
    ];

    public sendEmail(): void {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }
        const { name, email, shortMessage } = this.form.value;
        if (name && email && shortMessage) {
            this.formSendingDialogService.open();
            if (isPlatformServer(this.platformId)) {
                return;
            }
            this.http
                .post<string>('/send-email', { email, name, message: shortMessage })
                .pipe(
                    tap(() => {
                        this.resetForm();
                        return this.formSendingDialogService.changeToSuccess();
                    }),
                    catchError(() => {
                        this.resetForm();
                        this.formSendingDialogService.changeToError();
                        return EMPTY;
                    })
                )
                .subscribe();
        }
    }

    public resetForm(): void {
        this.form.reset();
        this.currentStep$$.next(0);
    }
}
