import { NgOptimizedImage, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, ViewEncapsulation } from '@angular/core';
import { AssetPipe } from '@libraries/asset/asset.pipe';
import { CaseAssetPath } from '../../case.path';
import { IShowCase } from '@shared/show-cases/show-cases.interface';
import { AnchorLabelPipe } from '@libraries/anchor/anchor-label.pipe';

@Component({
    selector: 'jsdaddy-case-header-shop[showCase]',
    standalone: true,
    imports: [NgTemplateOutlet, AssetPipe, NgOptimizedImage, AnchorLabelPipe],
    templateUrl: './case-header-shop.component.html',
    styleUrl: './case-header-shop.component.scss',
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CaseHeaderShopComponent {
    public showCase = input.required<IShowCase | undefined>();
    public assetPath = CaseAssetPath.HEADER;
}
