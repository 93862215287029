import { Pipe, PipeTransform } from '@angular/core';
import { IDevelopmentProcess } from './development-process.component.interfaces';

@Pipe({
    name: 'developmentProcess',
    standalone: true,
    pure: false,
})
export class DevelopmentProcessPipe implements PipeTransform {
    public transform(items: IDevelopmentProcess[], needSorting: boolean): IDevelopmentProcess[] {
        if (!needSorting) {
            return items;
        }
        return [...items].sort((a, b) => a.number - b.number);
    }
}
