import { NgOptimizedImage } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    DestroyRef,
    ElementRef,
    inject,
    signal,
    viewChild,
    ViewEncapsulation,
    WritableSignal,
} from '@angular/core';
import { NavigationEnd, Router, RouterLinkWithHref } from '@angular/router';
import { AssetPipe } from '@libraries/asset/asset.pipe';
import { SharedAssetPath } from '@shared/asset/asset.path';
import { BodyStylesService } from '@shared/body-styles/body-styles.service';
import { LinkedinComponent } from '@shared/linkedin/linkedin.component';
import { ResizeService } from '@shared/resize/resize.service';
import { AppPath } from '@shared/routes/app-routing.enum';
import { SectionWrapperComponent } from '@shared/section-wrapper/section-wrapper.component';
import { VisibilityPipe } from '@shared/visibility/visibility.pipe';
import { filter, map } from 'rxjs';
import { INavigation } from './header.interfaces';
import { HeaderPath } from './header.path';
import { MenuComponent } from './menu/menu.component';
import { ServicePageInfoComponent } from './service-page-info/service-page-info.component';
import { GithubButtonsComponent } from '@libraries/github-buttons/github-buttons.component';
import { GithubStarsService } from '@libraries/github/github-stars.service';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { AnchorLabelPipe } from '@libraries/anchor/anchor-label.pipe';

@Component({
    selector: 'jsdaddy-header',
    standalone: true,
    imports: [
        RouterLinkWithHref,
        SectionWrapperComponent,
        MenuComponent,
        ServicePageInfoComponent,
        AssetPipe,
        LinkedinComponent,
        VisibilityPipe,
        GithubButtonsComponent,
        NgOptimizedImage,
        AnchorLabelPipe,
    ],
    providers: [BodyStylesService, GithubStarsService],
    templateUrl: './header.component.html',
    styleUrl: './header.component.scss',
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements AfterViewInit {
    public readonly jsdaddyGithub = 'https://github.com/JsDaddy/';
    public readonly fakeBlockStyles = { height: '80px', transition: '0.5s', 'box-shadow': 'none' };
    public readonly fakeBlockAnimationStyles = {
        height: '346px',
        transition: '0.25s',
        'box-shadow': '0px 4px 30px rgba(0, 0, 0, 0.15)',
    };
    private readonly router = inject(Router);
    private readonly resizeService = inject(ResizeService);
    private readonly bodyStylesService = inject(BodyStylesService);
    public readonly assetPath = HeaderPath.ROOT;
    public readonly assetPathShared = SharedAssetPath.ROOT;

    private readonly destroyRef = inject(DestroyRef);

    public readonly navigation: INavigation[] = [
        { text: 'Service Page', isShowIcon: true, isActive: false },
        { text: 'Case Studies', link: AppPath.CASE_STUDIES, isActive: false },
        { text: 'Contact Us', link: AppPath.CONTACT_US, isActive: false },
        { text: 'Career', link: AppPath.CAREER, isActive: false },
    ];

    public navigations = toSignal(
        this.router.events.pipe(
            filter((event): event is NavigationEnd => event instanceof NavigationEnd),
            map((event: NavigationEnd) => {
                return this.navigation.map((nav: INavigation) => ({
                    ...nav,
                    isActive: nav.link ? event.url.includes(nav.link) : false,
                }));
            })
        )
    );

    public isShowMenu = false;
    public isShowDropdown = false;

    public header = viewChild<string, ElementRef<HTMLElement>>('header', { read: ElementRef });
    public width: WritableSignal<number | undefined> = signal(undefined);

    public ngAfterViewInit(): void {
        this.resizeService
            .createResizeSequence(this.header()?.nativeElement)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((value) => {
                this.width.set(value);
            });
    }

    public closeMenu(): void {
        this.isShowMenu = false;
        this.bodyStylesService.setOverflowBody(false);
    }

    public showMenu(): void {
        this.isShowMenu = true;
        this.bodyStylesService.setOverflowBody(true);
    }

    public toggleShowDropdown(value: boolean): void {
        this.isShowDropdown = value;
    }
}
