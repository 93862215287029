<div
    class="fake-block bg-white-opacity"
    [style]="isShowDropdown ? fakeBlockAnimationStyles : fakeBlockStyles"></div>
<jsdaddy-section-wrapper
    class="fixed bg-full-white"
    overflowXStyle="visible"
    overflowYStyle="visible">
    <header #header>
        <div class="left-side">
            <a class="logo" routerLink="/" [attr.aria-label]="'Home' | anchorLabel">
                <img
                    width="54"
                    height="29"
                    [ngSrc]="'logo' | asset: assetPathShared"
                    alt="JSDaddy logo" />
            </a>
            <nav>
                <ul>
                    @for (item of navigations(); track item.text) {
                        <li>
                            @if (!item.isShowIcon) {
                                <a
                                    [attr.aria-label]="item.text | anchorLabel"
                                    [routerLink]="item.link"
                                    class="links"
                                    [class.active]="item.isActive">
                                    {{ item.text }}
                                </a>
                            } @else {
                                <div
                                    class="dropdown" role="button" tabindex="0"
                                    (keyup.enter)="toggleShowDropdown(true)"
                                    (mouseenter)="toggleShowDropdown(true)"
                                    (mouseleave)="toggleShowDropdown(false)">
                                    <button class="dropbtn links">
                                        {{ item.text }}
                                        @if (item.isShowIcon) {
                                            <img
                                                width="6"
                                                height="3"
                                                [ngSrc]="'arrow-drop-down' | asset: assetPath"
                                                alt="Arrow dropdown" />
                                        }
                                    </button>
                                    <div
                                        class="dropdown-content"
                                        [style.visibility]="isShowDropdown | visibility"
                                        [style.height.px]="isShowDropdown ? 275 : 0">
                                        <jsdaddy-service-page-info
                                            [isShowDropdown]="isShowDropdown"
                                            [width]="width()"
                                            (closeDropdown)="toggleShowDropdown(false)" />
                                    </div>
                                </div>
                            }
                        </li>
                    }
                </ul>
            </nav>
        </div>
        <div class="right-side">
            <jsdaddy-linkedin />
            <jsdaddy-github-buttons title="All Stars"></jsdaddy-github-buttons>
            <button (click)="showMenu()" class="show-menu-button pointer" aria-label="Show menu">
                <div class="dash-1"></div>
                <div class="dash-2"></div>
            </button>
        </div>
    </header>
</jsdaddy-section-wrapper>
<jsdaddy-menu
    [style.visibility]="isShowMenu | visibility"
    [style.top]="isShowMenu ? 0 : '-150%'"
    [navigation]="navigation"
    [width]="width() || 0"
    (closeMenu)="closeMenu()" />
