<header [id]="section?.id" jsdaddyCareerDetailsScroll (sectionChange)="onSectionChange($event)">
    <button aria-label="Back" class="row ai-c" [routerLink]="careerRoutePath">
        <img
            width="16"
            height="16"
            class="back"
            [ngSrc]="'arrow-back-black' | asset: assetPathArrowButton"
            alt="Arrow black back" />
        <span class="back-text">Back to Offers</span>
    </button>

    <div class="column">
        <span class="uppercase h1-text career-title">{{ career()?.title }}</span>
        <div class="column hide-desktop hide-tablet career-mobile">
            <div class="row">
                <div class="career-item">
                    <img
                        class="flame"
                        alt="Flame"
                        [ngSrc]="'flame' | asset: assetPath"
                        width="24"
                        height="24" />
                </div>
                <ng-container
                    *ngTemplateOutlet="
                        iconText;
                        context: {
                            icon: 'location',
                            text: 'Ukraine, Kharkiv'
                        }
                    " />
            </div>
            <div class="row career-mobile-second-row">
                <ng-container
                    *ngTemplateOutlet="
                        iconText;
                        context: {
                            icon: 'remote',
                            text: 'Remote'
                        }
                    " />
                <ng-container
                    *ngTemplateOutlet="
                        iconText;
                        context: {
                            icon: 'full-time',
                            text: 'Full-Time'
                        }
                    " />
            </div>
        </div>
        <div class="row jc-sb career-title-bottom hide-mobile">
            <div>
                <img
                    class="flame"
                    alt="Flame"
                    [ngSrc]="'flame' | asset: assetPath"
                    width="24"
                    height="24" />
            </div>
            <ng-container
                *ngTemplateOutlet="
                    iconText;
                    context: {
                        icon: 'location',
                        text: 'Ukraine, Kharkiv'
                    }
                " />
            <ng-container
                *ngTemplateOutlet="
                    iconText;
                    context: {
                        icon: 'remote',
                        text: 'Remote'
                    }
                " />
            <ng-container
                *ngTemplateOutlet="
                    iconText;
                    context: {
                        icon: 'full-time',
                        text: 'Full-Time'
                    }
                " />
        </div>
        <div class="row tiles remove-mobile-wrapper-padding jc-sb">
            <ng-container
                *ngTemplateOutlet="
                    tile;
                    context: {
                        icon: 'required',
                        text: career()?.requiredSkills,
                        title: 'Required skills'
                    }
                " />
            <ng-container
                *ngTemplateOutlet="
                    tile;
                    context: {
                        icon: 'we-offer',
                        text: career()?.weOffer,
                        title: 'We offer'
                    }
                " />
        </div>
    </div>
</header>

<ng-template #iconText let-icon="icon" let-text="text">
    <div class="row career-item">
        <img [alt]="'img' + text" [ngSrc]="icon | asset: assetPath" width="24" height="24" />
        <span class="icon-text text">{{ text }}</span>
    </div>
</ng-template>

<ng-template #tile let-icon="icon" let-text="text" let-title="title">
    <div class="column bg-white tile">
        <div class="row ai-c">
            <img [alt]="'img' + title" [ngSrc]="icon | asset: assetPath" width="24" height="24" />
            <span class="uppercase ls-25 title">{{ title }}</span>
        </div>
        <span class="text">{{ text }}</span>
    </div>
</ng-template>
