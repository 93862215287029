import { inject, Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, Observable, shareReplay } from 'rxjs';

@Injectable()
export class CurrentRouteService {
    private readonly activatedRoute = inject(ActivatedRoute);

    public currentRoute$: Observable<ActivatedRoute> = inject(Router).events.pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd),
        map(() => {
            return this.getActiveRoute(this.activatedRoute);
        }),
        shareReplay(1)
    );

    private getActiveRoute(route: ActivatedRoute): ActivatedRoute {
        const { children } = route;
        let activeRoute!: ActivatedRoute;
         
        for (const child of children) {
            if (child.children.length === 0) {
                return child;
            }
            activeRoute = this.getActiveRoute(child);
        }
        return activeRoute;
    }
}
