import { inject } from '@angular/core';
import { HttpRequest, HttpHandlerFn } from '@angular/common/http';
import { switchMap } from 'rxjs';
import { RecaptchaService } from './recaptcha.service';

export function RecaptchaInterceptor(request: HttpRequest<unknown>, next: HttpHandlerFn) {
    const recaptchaService = inject(RecaptchaService);

    const { method } = request;
    if (method === 'GET' || method === 'OPTIONS') {
        return next(request);
    }
    const action = request.url.split('/').at(-1)?.replace('-', '_');
    return recaptchaService.recaptcha(action ?? 'default').pipe(
        switchMap((token) => {
            const headers = request.headers.append('recaptchaToken', token);
            const requestWithRecaptcha = request.clone({ headers });
            return next(requestWithRecaptcha);
        })
    );
}
