import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    viewChild,
    ViewEncapsulation,
} from '@angular/core';
import { SectionWrapperComponent } from '@shared/section-wrapper/section-wrapper.component';
import { CareerHeaderComponent } from './career-header/career-header.component';
import { CareerLifeAtComponent } from './career-life-at/career-life-at.component';
import { CareerOfferedComponent } from './career-offered/career-offered.component';
import { CareerPositionsComponent } from './career-positions/career-positions.component';

@Component({
    selector: 'jsdaddy-career',
    standalone: true,
    imports: [
        CareerPositionsComponent,
        SectionWrapperComponent,
        CareerOfferedComponent,
        CareerLifeAtComponent,
        CareerHeaderComponent,
    ],
    templateUrl: './career.component.html',
    styleUrl: './career.component.scss',
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CareerComponent {
    public careerPositionsComponent = viewChild<CareerPositionsComponent, ElementRef<HTMLElement>>(
        CareerPositionsComponent,
        { read: ElementRef }
    );
    public readonly assetPath = 'career';

    public scrollToPositions() {
        this.careerPositionsComponent()?.nativeElement?.scrollIntoView?.({ behavior: 'smooth' });
    }
}
