<section class="column">
    <jsdaddy-section-wrapper>
        <jsdaddy-header-title
            [needStar]="true"
            class="row"
            title="Successful Projects"
            subtitle="JS DADDY" />
        <div class="row blocks pos-rel">
            <img
                alt="Obj plus projects"
                class="obj-plus-projects hide-mobile hide-tablet"
                width="97"
                height="134"
                [ngSrc]="'obj-plus' | asset: assetPath" />
            <img
                alt="Projects background"
                class="projects-bg"
                width="492"
                height="492"
                [ngSrc]="'bg' | asset: assetPath : true" />
            <div class="block block-first">
                <div class="column">
                    <span class="h1-text full-white number">15+</span>
                    <span class="h3-text full-white subtitle">OUR CUSTOMERS</span>
                    <span class="span full-white"
                        >Listen to their needs, assess requirements, propose solutions, and
                        communicate effectively throughout the project.</span
                    >
                </div>
            </div>
            <div class="block">
                <div class="column">
                    <span class="h1-text full-white number">30+</span>
                    <span class="h3-text full-white subtitle">DELIVERING PROJECTS</span>
                    <span class="span full-white"
                        >Set clear expectations, maintain open communication, prioritize tasks, and
                        deliver high-quality work on time and within budget.</span
                    >
                </div>
            </div>
        </div>
        @if (showCases()) {
            <jsdaddy-row-black-white-wrapper title="cases" [needBottomImg]="true" ngSkipHydration>
                <div left class="slider pos-rel column">
                    <div #slides class="slides row">
                        @for (sc of showCases(); track sc?.slug) {
                            <div #slide class="slide column">
                                <span class="h3-text full-white">{{ sc?.title }}</span>
                                <span class="span full-white desc">{{ sc?.shortDescription }}</span>
                                <jsdaddy-border-button
                                    [link]="[caseStudies, sc?.slug || '']"
                                    title="Discover case" />
                            </div>
                        }
                    </div>
                    <div class="row btns">
                        <jsdaddy-arrow-button
                            [enabled]="activeSlideIndex() | sliderEnableDisable"
                            [type]="buttonTypeLink"
                            class="back"
                            (click)="goToSlide(false)"
                            [direction]="backDirection" />
                        <jsdaddy-arrow-button
                            [type]="buttonTypeLink"
                            class="forward"
                            [enabled]="
                                activeSlideIndex() | sliderEnableDisable: true : showCases()?.length
                            "
                            (click)="goToSlide()" />
                    </div>
                </div>
                <!--TODO: ngSkipHydration for this element-->
                <ng-content right />
            </jsdaddy-row-black-white-wrapper>
        }
    </jsdaddy-section-wrapper>
</section>
