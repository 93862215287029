import {
    ChangeDetectionStrategy,
    Component,
    inject,
    input,
    ViewEncapsulation,
} from '@angular/core';
import { RouterLinkWithHref } from '@angular/router';
import { BreadcrumbService } from './breadcrumbs.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { AnchorLabelPipe } from '@libraries/anchor/anchor-label.pipe';

@Component({
    selector: 'jsdaddy-breadcrumbs',
    standalone: true,
    imports: [RouterLinkWithHref, AnchorLabelPipe],
    templateUrl: './breadcrumbs.component.html',
    styleUrl: './breadcrumbs.component.scss',
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbsComponent {
    public isBlack = input(false);

    public breadcrumbs = toSignal(inject(BreadcrumbService).breadcrubms$);
}
