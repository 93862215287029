<div class="row pointer">
    <ng-container
        *ngTemplateOutlet="
            link;
            context: { $implicit: showCase()?.androidLink, type: 'google-play' }
        " />
    <ng-container
        *ngTemplateOutlet="link; context: { $implicit: showCase()?.iosLink, type: 'app-store' }" />
</div>

<ng-template #link let-link let-type="type">
    @if (link) {
        <a [href]="link" target="_blank" [attr.aria-label]="type | anchorLabel">
            <img [ngSrc]="type | asset: assetPath" width="125" height="37" [alt]="type" />
        </a>
    }
</ng-template>
