import { NgOptimizedImage, NgTemplateOutlet } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    OnInit,
    signal,
    ViewEncapsulation,
    WritableSignal,
} from '@angular/core';
import { RouterLinkWithHref } from '@angular/router';
import { AssetPipe } from '@libraries/asset/asset.pipe';
import { ChipComponent } from '@libraries/chip/chip.component';
import { SharedAssetPath } from '@shared/asset/asset.path';
import { BorderButtonComponent } from '@shared/border-button/border-button.component';
import { GridComponent } from '@shared/grid/grid.component';
import { HeaderTitleComponent } from '@shared/header-title/header-title.component';
import { AppPath } from '@shared/routes/app-routing.enum';
import positionsJson from 'src/assets/json/positions.json';
import { CareerAssetPath } from '../career.path';
import { ICareerPosition, ICareerPositionStyle } from './career-positions.interfaces';
import { CareerPositionsPipe } from './career-positions.pipe';
import { AnchorLabelPipe } from '@libraries/anchor/anchor-label.pipe';

@Component({
    selector: 'jsdaddy-career-positions',
    standalone: true,
    imports: [
        NgTemplateOutlet,
        RouterLinkWithHref,
        AssetPipe,
        CareerPositionsPipe,
        HeaderTitleComponent,
        ChipComponent,
        BorderButtonComponent,
        GridComponent,
        NgOptimizedImage,
        AnchorLabelPipe,
    ],
    templateUrl: './career-positions.component.html',
    styleUrl: './career-positions.component.scss',
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CareerPositionsComponent implements OnInit {
    public readonly assetPath = CareerAssetPath.POSITIONS;
    public readonly assetPathShared = SharedAssetPath.ROOT;
    public readonly contactUsPath = `/${AppPath.CONTACT_US}`;
    public readonly careerPath = `/${AppPath.CAREER}`;
    public positions: WritableSignal<ICareerPositionStyle[]> = signal([]);

    public ngOnInit() {
        const positions = positionsJson.reduce(
            (acc: ICareerPositionStyle[], obj: ICareerPosition, index: number) => {
                // TODO(xakeppok) test with marbles or move reduce cb to separate function
                const prevObj = acc[index - 1];
                const isNewGroup = !prevObj || prevObj.position !== obj.position;
                const styleClass = isNewGroup
                    ? !prevObj || prevObj.styleClass === 'ai-end'
                        ? 'ai-start'
                        : 'ai-end'
                    : prevObj.styleClass;
                return [
                    ...acc,
                    {
                        ...obj,
                        styleClass,
                    },
                ];
            },
            []
        );
        this.positions.set(positions);
    }
}
