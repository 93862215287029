import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import showCasesJson from 'src/assets/json/show-cases.json';
import { IShowCase } from './show-cases.interface';

@Injectable()
export class ShowCasesService {
    public getShowCases(): Observable<IShowCase[]> {
        return of(
            //type-coverage:ignore-next-line
            (showCasesJson as unknown as IShowCase[]).map(
                (showCase): IShowCase => ({
                    ...showCase,
                    type: showCase.type,
                })
            ) as IShowCase[]
        );
    }
}
