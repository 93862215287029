import {
    ChangeDetectionStrategy,
    Component,
    ViewEncapsulation,
    inject,
    input, computed,
} from '@angular/core';
import { ICareerPosition } from '@content/career/career-positions/career-positions.interfaces';
import { CareerDetailsService } from '../career-details.service';
import { CareerDetailsTitles } from '../career-details.titles';
import { CareerDetailsContent } from './career-details-content';
import { CareerDetailsScrollDirective } from "@content/career-details/career-details-scroll.directive";

@Component({
    selector: 'jsdaddy-career-details-content',
    standalone: true,
    imports: [
        CareerDetailsScrollDirective
    ],
    templateUrl: './career-details-content.component.html',
    styleUrl: './career-details-content.component.scss',
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CareerDetailsContentComponent {
    public career = input.required<ICareerPosition | undefined>();

    private readonly careerDetailsService = inject(CareerDetailsService);

    public tiles =  computed(() => {
        const currentCareer = this.career();
        return [
            new CareerDetailsContent(
                currentCareer!.responsibilities,
                CareerDetailsTitles.RESPONSIBILITIES
            ),
            new CareerDetailsContent(
                currentCareer!.requirements,
                CareerDetailsTitles.REQUIREMENTS
            ),
            new CareerDetailsContent(
                currentCareer!.niceToHave,
                CareerDetailsTitles.NICE_TO_HAVE
            ),
        ];
    });

    public onSectionChange(sectionId: string) {
        this.careerDetailsService.changeSection(sectionId);
    }
}
