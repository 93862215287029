import { isPlatformServer } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    OnInit,
    // type-coverage:ignore-next-line
    PLATFORM_ID,
    ViewEncapsulation,
    inject,
    WritableSignal,
    signal,
    DestroyRef,
} from '@angular/core';
import { distinctUntilChanged, fromEvent, map, startWith } from 'rxjs';
import { CareerDetailsService } from '../career-details.service';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';

@Component({
    selector: 'jsdaddy-career-details-sidebar',
    standalone: true,
    templateUrl: './career-details-sidebar.component.html',
    styleUrl: './career-details-sidebar.component.scss',
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CareerDetailsSidebarComponent implements OnInit {
    private careerDetailsService = inject(CareerDetailsService);
    private readonly elementRef = inject<ElementRef<HTMLElement>>(ElementRef);
    private readonly platformId = inject<string>(PLATFORM_ID);
    private readonly destroyedRef = inject(DestroyRef);

    public currentSection = toSignal(this.careerDetailsService.currentSection$);
    public isAbsolutePosition: WritableSignal<boolean | undefined> = signal(false);
    public sections = this.careerDetailsService.sections;

    public ngOnInit(): void {
        if (isPlatformServer(this.platformId)) {
            return;
        }
        fromEvent(window, 'scroll')
            .pipe(
                map(() => {
                    const fixedElementHeight = this.elementRef.nativeElement.clientHeight;
                    const scrollY = window.scrollY || document.documentElement.scrollTop;
                    return fixedElementHeight - scrollY < 442;
                }),
                startWith(false),
                distinctUntilChanged(),
                takeUntilDestroyed(this.destroyedRef)
            )
            .subscribe(this.isAbsolutePosition.set);
    }

    public handleKeydown(event: KeyboardEvent, sectionId: string) {
        if (event.key === 'Enter') {
            this.scrollTo(sectionId);
        }
    }

    public scrollTo(sectionId: string) {
        const sectionElement = document.getElementById(sectionId);
        if (sectionElement) {
            const offset = sectionElement.offsetTop - 80;
            window.scrollTo({ top: offset, behavior: 'smooth' });
        }
    }
}
