import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { ICareerPosition } from '@content/career/career-positions/career-positions.interfaces';
import { BreadcrumbsComponent } from '@shared/breadcrumbs/breadcrumbs.component';
import { SectionWrapperComponent } from '@shared/section-wrapper/section-wrapper.component';
import { of } from 'rxjs';
import positionsJson from 'src/assets/json/positions.json';
import { CareerDetailsContentComponent } from './career-details-content/career-details-content.component';
import { CareerDetailsHeaderComponent } from './career-details-header/career-details-header.component';
import { CareerDetailsSidebarComponent } from './career-details-sidebar/career-details-sidebar.component';
import { CareerDetailsTellUsComponent } from './career-details-tell-us/career-details-tell-us.component';
import { GridComponent } from '@shared/grid/grid.component';
import { MetaTagsService } from '@shared/meta-tags/meta-tags.service';
import { SingleContentComponent } from '@shared/meta-tags/meta-tags.component';

@Component({
    selector: 'jsdaddy-career-details',
    standalone: true,
    imports: [
        BreadcrumbsComponent,
        SectionWrapperComponent,
        CareerDetailsHeaderComponent,
        CareerDetailsSidebarComponent,
        CareerDetailsContentComponent,
        CareerDetailsTellUsComponent,
        GridComponent,
    ],
    templateUrl: './career-details.component.html',
    styleUrl: './career-details.component.scss',
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [MetaTagsService],
})
export class CareerDetailsComponent extends SingleContentComponent<ICareerPosition> {
    public contentJson$ = of(positionsJson);
}
