<section class="column pos-rel">
    <img
        alt="Glasses"
        class="glasses"
        width="1"
        height="1"
        [ngSrc]="'glasses' | asset: assetPathShared" />
    <div class="offered">
        <jsdaddy-section-wrapper>
            <div class="row pos-rel">
                <jsdaddy-header-title
                    title="What We Offer"
                    subtitle="Our benefits"
                    [needStar]="true" />
                <img
                    class="offered-bg"
                    alt="We offered bg"
                    width="492"
                    height="492"
                    [ngSrc]="'offered-bg' | asset: assetPath : true" />
            </div>
            <div class="row content pos-rel">
                @for (offer of offers; track offer.title) {
                    <div class="column block">
                        <div class="row jc-sb block-title ai-c jc-sb">
                            <span class="h3-text full-white">{{ offer.title }}</span>
                            <div class="row ai-c">
                                <div class="divider"></div>
                                <img
                                    [alt]="offer.title"
                                    [ngSrc]="offer.asset | asset: assetPath"
                                    width="30"
                                    height="33" />
                            </div>
                        </div>
                        <span class="span full-white text">{{ offer.text }}</span>
                    </div>
                }
                <div class="column hiring jc-sb pos-rel">
                    <span class="title full-white ls-25"
                        >WANT TO FEEL<br />
                        THIS BENEFITS?</span
                    >
                    <span class="h3-text full-white"
                        >WE'RE<br />
                        HIRING!</span
                    >
                    <jsdaddy-border-button (click)="onWatchOpeningClick()" title="See Openings" />
                    <img
                        alt="Obj plus"
                        class="obj-plus"
                        width="1"
                        height="1"
                        [ngSrc]="'obj-plus-2' | asset: assetPathShared" />
                </div>
            </div>
        </jsdaddy-section-wrapper>
    </div>
</section>
