import { DestroyRef, Directive, inject, OnInit, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { concatAll, find, Observable, switchMap, tap, withLatestFrom } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MetaTagsService } from '@shared/meta-tags/meta-tags.service';
import { CurrentRouteService } from '@shared/current-route/current-route.service';
import { IMetaTags } from '@shared/meta-tags/meta-tags.interface';

@Directive()
export abstract class SingleContentComponent<T extends { metaTags: IMetaTags; slug: string }>
    implements OnInit
{
    public readonly router = inject(Router);
    public readonly destroyRef = inject(DestroyRef);
    public readonly content: WritableSignal<T | undefined> = signal(undefined);

    private readonly metaTagsService = inject(MetaTagsService);
    private readonly currentRouteService = inject(CurrentRouteService);
    private readonly route = inject(ActivatedRoute);

    public abstract contentJson$: Observable<T[]>;
    public readonly content$: Observable<T | undefined> = (
        this.route.params as Observable<{ slug?: string }>
    ).pipe(
        switchMap(({ slug: id }) =>
            this.contentJson$.pipe(
                concatAll(),
                find(({ slug }) => slug === id)
            )
        )
    );

    public ngOnInit() {
        this.currentRouteService.currentRoute$
            .pipe(
                withLatestFrom(this.content$),
                tap(([, item]) => {
                    if (!item) {
                        this.router.navigate(['/not-found']).catch();
                    }
                    this.content.set(item);
                    this.metaTagsService.setMetaTags(item?.metaTags);
                }),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe();
    }
}
