<section class="row jc-sb">
    <div class="column jc-sb pos-rel text-technologies">
        <jsdaddy-header-title
            [black]="true"
            title="Technologies"
            subtitle="All major languages"
            [needStar]="true" />
        <div *ngTemplateOutlet="button; context: { $implicit: 'hide-tablet hide-mobile' }"></div>
    </div>

    <div class="plus"></div>
    <jsdaddy-grid class="grid-plus" [hideDesktop]="true" [hideTablet]="true" />
    <div class="vysa vysa-not-mobile hide-mobile"></div>

    <img
        [ngSrc]="'vysa' | asset: assetPath"
        class="vysa-mobile hide-desktop hide-tablet"
        width="544"
        height="223"
        alt="Vysa" />
    <div class="row right-side">
        @for (technology of technologies; track technology; let first = $first; let last = $last) {
            @if (first) {
                <div class="technology no-cursor hide-mobile no-animation"></div>
            }
            @if (technology) {
                <div class="technology">
                    @if (!first && !last) {
                        <img
                            width="68"
                            height="68"
                            [ngSrc]="technology | asset: assetPathShareTechnologies"
                            [alt]="technology" />
                    }
                </div>
            }
            @if (last) {
                <a class="technology column jc-sb no-animation" [routerLink]="contactUsPath">
                    <p class="title uppercase ls-25">
                        And<br />
                        others...
                    </p>
                    <span [attr.aria-label]="'Contact us' | anchorLabel" class="uppercase h3-text"
                        >Want to discover all?</span
                    >
                    <jsdaddy-border-button title="Let's talk" />
                </a>
            }
        }
    </div>
    <ng-container *ngTemplateOutlet="button; context: { $implicit: 'hide-desktop' }"></ng-container>
</section>

<ng-template #button let-class>
    <div [class]="['row ai-c become-a-client', class].join(' ')">
        <div class="arrow"></div>
        <jsdaddy-button [link]="contactUsPath" [type]="buttonTypeGhost" title="Become a Client!" />
    </div>
</ng-template>
