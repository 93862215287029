import { AssetPipe } from '@libraries/asset/asset.pipe';
import { ButtonType } from '@shared/button/button.enums';
import { ButtonComponent } from '@shared/button/button.component';
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { NgOptimizedImage, NgTemplateOutlet } from '@angular/common';
import { HeaderTitleComponent } from '@shared/header-title/header-title.component';
import { BorderButtonComponent } from '@shared/border-button/border-button.component';
import { RouterLinkWithHref } from '@angular/router';
import { AppPath } from '@shared/routes/app-routing.enum';
import { HomeAssetPath } from '../home.path';
import { SharedAssetPath } from '@shared/asset/asset.path';
import { GridComponent } from '@shared/grid/grid.component';
import { AnchorLabelPipe } from '@libraries/anchor/anchor-label.pipe';

@Component({
    selector: 'jsdaddy-technologies',
    standalone: true,
    imports: [
        NgTemplateOutlet,
        RouterLinkWithHref,
        ButtonComponent,
        HeaderTitleComponent,
        AssetPipe,
        BorderButtonComponent,
        GridComponent,
        NgOptimizedImage,
        AnchorLabelPipe,
    ],
    templateUrl: './technologies.component.html',
    styleUrl: './technologies.component.scss',
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TechnologiesComponent {
    public readonly contactUsPath = `/${AppPath.CONTACT_US}`;
    public readonly assetPath = HomeAssetPath.TECHNOLOGIES;
    public readonly assetPathShareTechnologies = SharedAssetPath.TECHNOLOGIES;
    public readonly assetPathShared = SharedAssetPath.ROOT;
    public readonly buttonTypeGhost = ButtonType.Ghost;

    public readonly technologies = [
        '',
        'flutter',
        'angular',
        'react',
        'js-ts',
        'nodejs',
        'nestjs',
        'dart',
        '',
    ];
}
