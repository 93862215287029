import { NgOptimizedImage } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    DestroyRef,
    ElementRef,
    inject,
    input,
    viewChild,
    ViewEncapsulation,
} from '@angular/core';
import { AssetPipe } from '@libraries/asset/asset.pipe';
import { ImageUrlPipe } from '@shared/image-url/image-url.pipe';
import { ResizeService } from '@shared/resize/resize.service';
import { ShowCaseType } from '@shared/show-cases-list/show-cases-list.enum';
import { tap } from 'rxjs';
import { FramePipe } from '../frame/frame.pipe';
import { IShowCase } from '../show-cases.interface';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'jsdaddy-image-with-frame[showCase]',
    standalone: true,
    imports: [FramePipe, AssetPipe, ImageUrlPipe, NgOptimizedImage],
    templateUrl: './image-with-frame.component.html',
    styleUrl: './image-with-frame.component.scss',
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageWithFrameComponent implements AfterViewInit {
    public showCase = input.required<IShowCase | undefined>();

    public image = input<number>();

    public half = input(false);

    public readonly webShowCaseType = ShowCaseType.WEB;

    public readonly imageRef = viewChild<string, ElementRef<HTMLElement>>('imageRef', {
        read: ElementRef,
    });

    private readonly cdr = inject(ChangeDetectorRef);

    private readonly resizeService = inject(ResizeService);

    private readonly destroyRef = inject(DestroyRef);

    public height = 0;

    public ngAfterViewInit() {
        if (this.half()) {
            this.resizeService
                .createResizeSequence(this.imageRef()?.nativeElement)
                .pipe(
                    tap((width) => {
                        if (!this.showCase()) return;
                        switch (this.showCase()?.type) {
                            case ShowCaseType.MOBILE:
                                this.height = width;
                                break;
                            case ShowCaseType.WEB:
                                this.height = width * 0.3;
                                break;
                            case ShowCaseType.LANDSCAPE_TABLET:
                                this.height = width * 0.375;
                                break;
                            case ShowCaseType.PORTRAIT_TABLET:
                                this.height = width * 0.65;
                                break;
                        }
                        this.cdr.detectChanges();
                    }),
                    takeUntilDestroyed(this.destroyRef)
                )
                .subscribe();
        }
    }
}
