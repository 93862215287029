import { Injectable } from '@angular/core';
import { DefaultUrlSerializer, UrlTree } from '@angular/router';

@Injectable()
export class CustomUrlSerializer extends DefaultUrlSerializer {
    public override parse(url: string): UrlTree {
        return super.parse(decodeURIComponent(url));
    }

    public override serialize(tree: UrlTree) {
        return decodeURIComponent(super.serialize(tree));
    }
}
