[
    {
        "title": "Ima Methadone",
        "slug": "ima-methadone",
        "assetsFolder": "ima-methadone",
        "category": "Mobile Application",
        "type": "landscape-tablet",
        "solutions": [
            "Architecture",
            "Research",
            "Frontend Development",
            "UX/UI"
        ],
        "images": [
            1,
            2,
            3,
            4,
            5
        ],
        "technologies": [
            "Flutter",
            "Dart",
            "Android Studio",
            "Smartsheet",
            "Firebase Crashlytics",
            "Firebase Database",
            "Firebase Distribution",
            "Firebase",
            "Redux",
            "Zebra",
            "GitHub Actions"
        ],
        "challenge": "Our challenge was to develop a mobile application using Flutter that would allow nurses to work with dispensers and printers via 3rd party devices. The app needed to provide an intuitive interface for the nurses to select a dispenser, replace bottles, access client order lists, dispense doses, and print labels. The main hurdle was to integrate the dispenser and printer APIs seamlessly within the app to provide a streamlined user experience.",
        "solution": "We designed a mobile app that was easy to use, yet powerful enough to manage the complex tasks of dispensing medications. Our team successfully integrated the dispenser and printer APIs, allowing nurses to select a dispenser, replace bottles, access client order lists, dispense doses, and print labels with ease. The app was designed with the needs of nurses in mind, making it intuitive and straightforward to use.",
        "result": "The app significantly improved the efficiency of the dispensing process, freeing up nurses' time to focus on patient care. With the help of the app, nurses could quickly and accurately dispense medications, saving time and effort. The app's ability to save dispensed data on the server ensured the accuracy of the information, reducing errors and improving patient safety. Our team successfully delivered a mobile app that met the needs of our client and their users.",
        "startDate": "March 2019",
        "endDate": "Present",
        "industry": "Healthcare",
        "country": "USA",
        "shortDescription": "Our team developed a Flutter mobile app for nurses that allows them to work with dispensers and printers through 3rd party devices. The app streamlines the dispensing process and saves nurses time and effort.",
        "description": "Our team developed a mobile application for nurses that aimed to simplify the process of dispensing medications to patients by enabling nurses to work with Ivek dispensers and Zebra Technologies Bluetooth printers through 3rd party devices. The app was built using Flutter technology and the dispenser and printer APIs were seamlessly integrated within the app.\n\nThanks to compatibility with Ivek and Zebra Technologies equipment, the application provided an easy access to the dispenser and printer. Our developers used the APIs of these devices to integrate them into the app. This way, nurses could quickly and accurately dispense medications and print labels.\n\nThe app's intuitive user interface and real-time operation provided nurses with the ability to select a dispenser, replace bottles, access client order lists, dispense doses, and print labels with minimal effort. The app also allowed nurses to enter and save dispensing data on the server, ensuring the accuracy of the information and reducing errors.\n\nIvek dispenser and Zebra Technologies printer were chosen based on their high performance and reliability, ensuring the safety and accuracy of the medication dispensing process.\n\nOverall, the mobile application developed by our team improved the efficiency of the dispensing process, freeing up nurses' time to focus on providing quality patient care. The app was easy to use, efficient, and ensured the accuracy and safety of the medication dispensing process.",
        "metaTags": {
            "title": "JSDaddy.io - Ima Methadone Case Study",
            "description": "Discover how JSDaddy.io developed a Flutter mobile app to streamline the dispensing process for nurses, integrating with dispensers and printers for efficient and accurate medication management.",
            "keywords": "JSDaddy, Ima Methadone, case study, Flutter app, Dart, medication management, healthcare solutions, dispenser integration, printer integration",
            "og:url": "https://jsdaddy.io/case-studies/ima-methadone",
            "og:title": "JSDaddy.io - Ima Methadone Case Study",
            "og:description": "Discover how JSDaddy.io developed a Flutter mobile app to streamline the dispensing process for nurses, integrating with dispensers and printers for efficient and accurate medication management."
        }
    },
    {
        "title": "Moscow Dictor",
        "slug": "moscow-dictor",
        "category": "Web Application",
        "assetsFolder": "moscow-dictor",
        "webLink": "https://dictor.moscow/",
        "type": "web",
        "solutions": [
            "Architecture",
            "Frontend Development",
            "Backend Development"
        ],
        "images": [
            1,
            2,
            3,
            4,
            5
        ],
        "technologies": [
            "Angular",
            "CSS3",
            "JS TS",
            "NgRx",
            "RxJS",
            "NestJS",
            "NodeJS",
            "Notion",
            "Postgres"
        ],
        "challenge": "We faced the task to develop a user interface complying with different user groups' needs and providing for their seamless interaction. Our task was to provide users with the ability to create and customize their personal accounts according to their selected user type, to ensure convenience in working with streaming audio, flexible search and filtering, as well as regular updating of the database and seamless integration of the application with social networks.",
        "solution": "To achieve the above goals, we utilized key features provided by the Angular framework and other up-to-date technologies. We ensured real-time database updating, as well as the ability to interact with social networks. The best UI/UX principles were implemented to create a convenient and intuitive interface that enables flexible search and efficient account management. A customizable player was embedded for seamless work with the artists' audio samples.",
        "result": "We created a comprehensive application that provides only up-to-date voice-over samples and offers flexible search capabilities for producers. It is seamlessly integrated with social networks. The application interface is designed to be user-friendly for both types of users, providing efficient playlist management, sorting options, and the ability to add other users' accounts to favorites. It is concise and at the same time stylish and visually appealing to meet the demanding taste of the art community.",
        "startDate": "June, 20",
        "endDate": "February, 21",
        "industry": "Voice and Audiovisual Art",
        "country": "Russia",
        "shortDescription": "This web application provides professional information about voice-over artists who offer their services for various industry sectors, such as radio and television, audio dubbing for movies or animated cartoons, audio-books, business content, advertising, IVR systems, video games, etc. It enables interested producers to search for talented specialists in accordance with their business needs or creative tasks.",
        "description": "Moscow Dictor is a comprehensive web application that demonstrates many key features provided by the Angular framework. The application allows users to create and customize their personal profiles, upload audio samples up to 30 seconds long, view profiles of other users, add them to favorites. It provides a variety of options for playlists management. They can be created, shared, uploaded, or saved to the user's profile.\n\nThe application provides for the two main types of users—voice-over artists and producers. Voice-over artists fill out their profiles with professional information about themselves and then attach relevant samples that may interest producers. Producers have access to a convenient and flexible search function to find the voice-over artists or samples they are interested in.\n\nThe project also includes the administration feature that is not accessible to regular users and allows for data filtering, sorting, editing, and deletion, ensuring efficient data management.\n\nTo sum up, the project is a multi-functional application featuring a comprehensive set of tools that allows professionals to effectively showcase their skills and enables interested producers to find talented specialists who meet their unique business needs or creative tasks.",
        "metaTags": {
            "title": "JSDaddy.io - Moscow Dictor Case Study",
            "description": "Learn how JSDaddy.io developed a web application for Moscow Dictor, providing professional information about voice-over artists with seamless integration of audio streaming and social network features.",
            "keywords": "JSDaddy, Moscow Dictor, case study, web application, voice-over artists, audio streaming, social network integration",
            "og:url": "https://jsdaddy.io/case-studies/moscow-dictor",
            "og:title": "JSDaddy.io - Moscow Dictor Case Study",
            "og:description": "Learn how JSDaddy.io developed a web application for Moscow Dictor, providing professional information about voice-over artists with seamless integration of audio streaming and social network features."
        }
    },
    {
        "title": "Share Mobile",
        "slug": "share-mobile",
        "assetsFolder": "share-mobile",
        "category": "Mobile Application",
        "type": "mobile",
        "solutions": [
            "Research",
            "Architecture",
            "Frontend Development",
            "Backend Development",
            "Optimization"
        ],
        "images": [
            1,
            2,
            3,
            4,
            5
        ],
        "technologies": [
            "Flutter",
            "Dart",
            "Xcode",
            "Android Studio",
            "Asana",
            "Firebase Crashlytics",
            "Firebase Distribution",
            "Firebase",
            "Google Maps",
            "Redux",
            "Docker",
            "Postgres",
            "RxJS",
            "NestJS",
            "JS TS",
            "NodeJS",
            "Dbeaver"
        ],
        "challenge": "The challenge was to create a mobile app that would allow users to easily share items within their community. We needed to implement a map view that could display a large number of items, along with advanced filters to help users find what they were looking for. We also needed to implement a donation feature to enable users to purchase items for others or receive donations.",
        "solution": "We developed Share, a mobile app with a map view that displayed shares on Google Maps. Users could add shares to a wish list, and an All Shares tab with infinite scroll allowed for easy navigation. In the Profile tab, users could view their wish list items and use a donation feature. We also built a server on NestJS to handle sharing and donation transactions.",
        "result": "The Share app was successfully developed and delivered to our client, providing users with an easy and efficient way to browse and share items within their community. The integration with Google Maps, server-side clustering, and infinite scroll features ensured that users could navigate the app easily. The donation feature provided a way for users to give back to the community, making the app a valuable tool for fostering a sense of community and cooperation. Overall, the Share app improved the quality of life for users, making it easier for them to share and donate items with their community.",
        "startDate": "October 2021",
        "endDate": "March 2023",
        "industry": "Social",
        "country": "USA",
        "shortDescription": "Share is a mobile app that allows users to register businesses, non-profit organizations, or personal accounts. The app includes a \"Map\" view and an \"All Shares\" tab, and a \"Profile\" tab. On the map view, users can see all the shares posted by other users, add them to their wish list, view prices and locations, and use advanced filters. The app also features a server that enables sharing and donation transactions.",
        "description": "Share is a mobile app that enables users to register businesses, non-profit organizations, or personal accounts. Once registered, users can add friends and access the app's three main tabs: Map View, All Shares, and Profile.\n\nThe Map View tab displays all the shares posted by other users on Google Maps, with the ability to add them to a wish list, view prices and locations, and use advanced filters. To manage the high number of items on the map, we implemented server-side clustering.\n\nThe All Shares tab displays all shares posted by users in a list format. To make navigation easier, we added an infinite scroll feature.\n\nThe Profile tab includes a wishlist tab and a donation tab. In the wishlist tab, users can view their wish list items, while the donation tab allows users to purchase items for others or receive donations.\n\nThe app also features a server built on NestJS that enables sharing and donation transactions.\n\nThe Share app provides users with an easy and efficient way to browse and share items within their community. The integration with Google Maps, server-side clustering, and infinite scroll features ensure that users can navigate the app easily, while the donation feature provides a way for users to give back to the community.",
        "metaTags": {
            "title": "JSDaddy.io - Share Mobile Case Study",
            "description": "Discover how JSDaddy.io developed Share, a mobile app enabling users to register businesses, nonprofits, or personal accounts, featuring advanced filters, wishlist, and donation functionalities.",
            "keywords": "JSDaddy, Share Mobile, case study, mobile app, business registration, nonprofit registration, personal accounts, advanced filters, wishlist, donations",
            "og:url": "https://jsdaddy.io/case-studies/share-mobile",
            "og:title": "JSDaddy.io - Share Mobile Case Study",
            "og:description": "Discover how JSDaddy.io developed Share, a mobile app enabling users to register businesses, nonprofits, or personal accounts, featuring advanced filters, wishlist, and donation functionalities."
        }
    },
    {
        "title": "Advenium Web",
        "slug": "advenium-web",
        "category": "Web Application",
        "assetsFolder": "adv-web",
        "webLink": "https://app.advenium.com/",
        "type": "web",
        "solutions": [
            "Architecture",
            "Frontend Development"
        ],
        "images": [
            1,
            2,
            3,
            4,
            5
        ],
        "technologies": [
            "Angular",
            "Css3",
            "JS TS",
            "NgRx",
            "Smartsheet",
            "RxJS",
            "Kendo",
            "Notion"
        ],
        "challenge": "The challenge we faced in developing the web app was designing an interface that could accommodate the diverse needs of its users. The app needed to be intuitive and easy to use for patients, staff managers, assessment form administrators, payers, and other users, each with unique roles and requirements. We had to develop a user interface that was adaptable to different user groups' while still maintaining consistency across the app.",
        "solution": "Advenium is a web app designed for doctors and nurses to manage patient data, appointments, and progress. It includes assessment forms for mental health evaluations, online payment processing, and scheduling features. Advenium generates invoices and tracks payments for billing and accounting purposes, and notifies users of new appointments or schedule changes.",
        "result": "Advenium is a powerful web app that simplifies patient management for healthcare providers. It allows doctors and nurses to manage patient data and appointments, and provides mental health assessment forms that are automatically scored and stored in patient files. Advenium also features online payment processing, invoicing, and tracking for billing and accounting purposes. With its user-friendly interface, Advenium streamlines patient management and enhances the quality of care.",
        "startDate": "August 21",
        "endDate": "Present",
        "industry": "Healthcare",
        "country": "USA",
        "shortDescription": "This is a multi-functional tool designed for psychiatric hospitals. It streamlines administrative and financial processes with form and table management, scheduling, and payment processing. The app improves organization and patient record management, leading to better care and treatment.",
        "description": "The multi-functional app is designed to provide a comprehensive tool for use in a psychiatric hospital. It is a cloud-based platform that enables users to manage various processes and operations in the hospital. The app is accessible from web device with an internet connection, allowing for ease of use and accessibility.\n\nOne of the core features of the app is form and table management. The app allows users to create, edit, and manage various forms and tables used in the hospital. This includes forms for patient admissions, discharge, and progress reports, among others. Tables can be used to manage patient records, medication schedules, and treatment plans, among other things. The app makes it easy to customize forms and tables to suit the needs of the hospital.\n\nThe scheduling module is another important feature of the app. The app provides a flexible scheduling tool that allows users to manage appointments, meetings, and other events in the hospital. Users can set up recurring appointments, schedule multiple patients, and manage availability, ensuring that scheduling conflicts are avoided. The scheduling module also provides alerts and notifications to remind users of upcoming appointments, making it easier to stay on top of the hospital's schedule.\n\nOverall, the multi-functional app provides a comprehensive tool for managing various processes and operations in a psychiatric hospital. Its flexibility and ease of use make it an indispensable tool for hospital administrators, doctors, and other healthcare professionals.",
        "metaTags": {
            "title": "JSDaddy.io - Advenium Web Case Study",
            "description": "Learn how JSDaddy.io developed a multi-functional tool for psychiatric hospitals, streamlining administrative and financial processes with comprehensive management features.",
            "keywords": "JSDaddy, Advenium Web, case study, psychiatric hospitals, administrative processes, financial processes, healthcare solutions",
            "og:url": "https://jsdaddy.io/case-studies/advenium-web",
            "og:title": "JSDaddy.io - Advenium Web Case Study",
            "og:description": "Learn how JSDaddy.io developed a multi-functional tool for psychiatric hospitals, streamlining administrative and financial processes with comprehensive management features."
        }
    },
    {
        "title": "Share Web",
        "slug": "share-web",
        "category": "Web Application",
        "assetsFolder": "share-web",
        "webLink": "http://159.223.6.95/",
        "type": "web",
        "solutions": [
            "Research",
            "Architecture",
            "Frontend Development",
            "Backend Development",
            "Optimization"
        ],
        "images": [
            1,
            2,
            3,
            4,
            5
        ],
        "technologies": [
            "Angular",
            "NestJS",
            "NodeJS",
            "Css3",
            "NgRx",
            "JS TS",
            "Dbeaver",
            "Docker",
            "Asana",
            "Google Maps"
        ],
        "challenge": "Our challenge was to develop an Angular-based admin panel for the Share mobile app that would enable managers to approve or reject users, shares, and user documents. Additionally, admins needed to be able to create new categories for items to organize them more effectively.",
        "solution": "We developed Share-web, an Angular-based admin panel that provided managers and admins with an intuitive interface to manage user and item data efficiently. The panel included a user list and a list of all items, each with three possible statuses: approved, rejected, or pending. Managers and admins could easily approve or reject users, shares, and user documents using the provided functionality. Additionally, admins could create new categories for items to organize them more effectively.",
        "result": "The Share-web admin panel was successfully developed and delivered to our client, improving the user experience for managers and admins by streamlining the management process and making it more efficient. The intuitive interface enabled managers to manage users and items easily, reducing the time and effort required to manage data in the app. Additionally, the ability to create new categories for items gave admins the flexibility to organize the app's data more effectively. Overall, Share-web improved the quality of life for managers and admins and enabled them to better manage the app's data.",
        "startDate": "October 2021",
        "endDate": "March 2022",
        "industry": "Social",
        "country": "USA",
        "shortDescription": "Share-web is an Angular-based admin panel for the Share mobile app that enables managers to approve or reject users, shares, and user documents. Admins can also create new categories for items to organize them more effectively.",
        "description": "In addition to its user-friendly interface, Share-web offers a range of features that make managing user and item data easier for managers and admins. The user list feature allows admins to view a complete list of all registered users and their respective statuses, providing them with a quick and easy way to monitor the users' activities. The item list feature provides a similar view of all items and their corresponding status, enabling admins to keep track of item approval and rejection.\n\nMoreover, the Share-web admin panel's approval and rejection functionality streamlines the process of managing users and items. Admins can easily approve or reject users, shares, and user documents with just a few clicks. This feature saves time and effort, as admins can make decisions quickly and easily, without the need for manual intervention.\n\nAnother key feature of the Share-web admin panel is the ability to create new categories for items. This feature enables admins to organize items more effectively, making it easier to manage and find specific items. By creating new categories, admins can streamline the item management process and make it more efficient.\n\nOverall, the Share-web admin panel was developed with a focus on improving the management process for admins and managers. Its user-friendly interface, user and item list features, approval and rejection functionality, and category creation feature all work together to streamline data management and make it more efficient. The result is a more effective and efficient management process, which ultimately improves the user experience for admins and users alike.",
        "metaTags": {
            "title": "JSDaddy.io - Share Web Case Study",
            "description": "Discover how JSDaddy.io developed an Angular-based admin panel for the Share mobile app, enabling efficient user and item management with advanced features.",
            "keywords": "JSDaddy, Share Web, case study, Angular admin panel, user management, item management, mobile app, business solutions",
            "og:url": "https://jsdaddy.io/case-studies/share-web",
            "og:title": "JSDaddy.io - Share Web Case Study",
            "og:description": "Discover how JSDaddy.io developed an Angular-based admin panel for the Share mobile app, enabling efficient user and item management with advanced features."
        }
    },
    {
        "title": "Company Id",
        "slug": "company-id",
        "assetsFolder": "company-id",
        "category": "Mobile Application",
        "type": "mobile",
        "solutions": [
            "UI/UX creation",
            "Architecture",
            "Frontend Development",
            "Backend Development",
            "Optimization"
        ],
        "images": [
            1,
            2,
            3,
            4,
            5
        ],
        "technologies": [
            "Flutter",
            "Dart",
            "Xcode",
            "Android Studio",
            "Firebase",
            "Firebase Crashlytics",
            "Firebase Distribution",
            "Docker",
            "Postgres",
            "RxJS",
            "NestJS",
            "GetX",
            "JS TS",
            "NodeJS",
            "Dbeaver",
            "GitHub Actions"
        ],
        "challenge": "Our challenge was to develop a mobile app for time tracking, leave requests, salary calculation, and salary reviews for employees. We needed to design an intuitive interface that allowed users to manage their work time, request time off, and view their personal information and salary data. Additionally, we had to develop a backend to support the app's functionality.",
        "solution": "We developed Company Id, a mobile app that allowed employees to manage their work time, request time off, and view their personal information and salary data. The app included a calendar with all employee logs and three notification tabs: user notifications, leave/sick leave requests, and salary review requests for admins. In addition, we developed a NestJS-based backend to support the app's functionality.",
        "result": "The Company Id mobile app and its backend were successfully developed and delivered to our client, improving the user experience for employees and admins by streamlining time tracking and leave requests. The intuitive interface enabled users to manage their work hours and request time off easily. The user tab provided a convenient way for users to access their colleagues' information, and the personal information tab displayed all user data. The notification feature provided timely reminders and updates to users and admins alike. The backend supported the app's functionality, ensuring a seamless experience for users. Overall, Company Id improved the quality of life for employees and admins, helping them manage their time and requests more efficiently.",
        "startDate": "April 2022",
        "endDate": "March 2023",
        "industry": "Business Relationship",
        "country": "UA",
        "shortDescription": "Company Id is a mobile app that allows employees to manage their work time, request time off, and view their personal information and salary data. It features a calendar, notification tabs, and an intuitive interface, all supported by a NestJS-based backend.",
        "description": "Company Id is a powerful mobile app designed to help employees manage their work time, leave requests, and salary data with ease. With an intuitive interface and a feature-rich design, the app makes it easy for employees to log their work hours, request time off, and view their personal information and salary data. The app includes a calendar with all employee logs and three notification tabs: user notifications, leave/sick leave requests, and salary review requests for admins. Users can access their colleagues' information via the user tab and view their personal information, including their salary data, through the personal information tab. The app is supported by a robust NestJS-based backend that ensures seamless functionality and reliability. Overall, Company Id is a user-friendly, efficient, and effective mobile app that makes managing work time and leave requests a breeze.",
        "metaTags": {
            "title": "JSDaddy.io - Company ID Case Study",
            "description": "Discover how JSDaddy.io developed a mobile app for employees to manage work time, leave requests, and salary data, with features like calendar integration and notifications, supported by a robust NestJS-based backend.",
            "keywords": "JSDaddy, Company ID, case study, mobile app, time management, leave requests, salary management, NestJS backend, employee management",
            "og:url": "https://jsdaddy.io/case-studies/company-id",
            "og:title": "JSDaddy.io - Company ID Case Study",
            "og:description": "Discover how JSDaddy.io developed a mobile app for employees to manage work time, leave requests, and salary data, with features like calendar integration and notifications, supported by a robust NestJS-based backend."
        }
    },
    {
        "title": "Ima Mobile Notes",
        "slug": "ima-mobile-notes",
        "assetsFolder": "ima-mobile-notes",
        "androidLink": "https://play.google.com/store/apps/details?id=com.jsdaddy.ima&hl=ru&gl=US",
        "iosLink": "https://apps.apple.com/us/app/imaserve-ehr-mobile/id1475133304",
        "category": "Mobile Application",
        "type": "mobile",
        "solutions": [
            "UI/UX creation",
            "Architecture",
            "Frontend Development"
        ],
        "images": [
            1,
            2,
            3,
            4,
            5
        ],
        "technologies": [
            "Flutter",
            "Dart",
            "Xcode",
            "Android Studio",
            "Smartsheet",
            "Firebase Crashlytics",
            "Firebase Distribution",
            "Firebase",
            "Redux",
            "RxDart",
            "GitHub Actions"
        ],
        "challenge": "The challenge for Ima Mobile Notes was to create a user-friendly application that allowed doctors to efficiently and effectively manage their patients' medical records. The app needed to provide an intuitive form creation process, offer local draft storage, and be able to store and access data offline or in areas with poor internet connectivity.",
        "solution": "Ima Mobile Notes was built using Flutter, a cross-platform framework, and Hive, a NoSQL database. The app's intuitive user interface enables doctors to easily create patient forms, add or edit data, and sign forms electronically. The app's local draft storage feature enables doctors to save incomplete forms to their device, edit them later, and then submit them to the server. Hive was used to store and manage patient data, which makes it possible to access data offline or in areas with poor internet connectivity.",
        "result": "Ima Mobile Notes provides doctors with a powerful and user-friendly tool for managing their patients' medical records. The app's intuitive user interface makes it easy to create patient forms, while its local draft storage feature ensures doctors can easily save incomplete forms and submit them to the server later. Additionally, the app's use of Hive ensures that doctors can access patient data even in areas with poor internet connectivity. Overall, Ima Mobile Notes has helped doctors save time, deliver better care to their patients, and improve medical record keeping.",
        "startDate": "January 2018",
        "endDate": "Present",
        "industry": "Healthcare",
        "country": "USA",
        "shortDescription": "Ima Mobile Notes is a medical notes application designed to help doctors create and store patient forms. With features like local draft storage and an intuitive form creation process, Ima Mobile Notes is the perfect tool for doctors to manage their patients' medical records.",
        "description": "Ima Mobile Notes is a medical notes application designed to help doctors create and store patient forms. With features like local draft storage and an intuitive form creation process, Ima Mobile Notes is the perfect tool for doctors to manage their patients' medical records. The app leverages a robust local database system, Hive, which allows doctors to seamlessly store and access patient data on their device. This feature makes it easy for doctors to manage medical notes, even when they're offline or in areas with poor internet connectivity. Additionally, the app's intuitive user interface ensures doctors can quickly and easily create new patient forms, which they can then share with colleagues and patients for easy access to important medical data. Overall, Ima Mobile Notes is a powerful and user-friendly medical notes app that helps doctors deliver better care to their patients.",
        "metaTags": {
            "title": "JSDaddy.io - Ima Mobile Notes Case Study",
            "description": "Discover how JSDaddy.io developed Ima Mobile Notes, a medical notes app for doctors, featuring local draft storage and an intuitive form creation process.",
            "keywords": "JSDaddy, Ima Mobile Notes, case study, medical notes app, local draft storage, form creation, healthcare solutions",
            "og:url": "https://jsdaddy.io/case-studies/ima-mobile-notes",
            "og:title": "JSDaddy.io - Ima Mobile Notes Case Study",
            "og:description": "Discover how JSDaddy.io developed Ima Mobile Notes, a medical notes app for doctors, featuring local draft storage and an intuitive form creation process."
        }
    },
    {
        "title": "Advenium Tablet",
        "slug": "advenium-tablet",
        "category": "Mobile Application",
        "assetsFolder": "adv-tablet",
        "type": "landscape-tablet",
        "solutions": [
            "Architecture",
            "Frontend Development"
        ],
        "webLink": "https://app.advenium.com/tablet/apk/current",
        "images": [
            1,
            2,
            3,
            4,
            5
        ],
        "technologies": [
            "Flutter",
            "Dart",
            "Xcode",
            "Android Studio",
            "Firebase Crashlytics",
            "Firebase Distribution",
            "Redux",
            "Digital Persona",
            "Smartsheet",
            "RxDart",
            "GitHub Actions",
            "Chart"
        ],
        "challenge": "Our challenge was to develop a mobile application that provides hospitals with an efficient and user-friendly way to manage sessions, patients, and feedback. We needed to integrate a 3rd party device, a digital persona fingerprint reader, into the app and provide advanced search and filter functionality. We also needed to ensure that the app was intuitive and easy to use for both doctors and patients.",
        "solution": "We developed a mobile app that seamlessly integrates with a digital persona fingerprint reader, providing a streamlined experience for doctors and patients. The app includes advanced search and filter functionality for sessions and patients, making it easy for doctors to manage their schedules. In Provider mode, doctors can mark attendance, leave notes, and review session information, while in Kiosk mode, patients can answer questions and provide feedback. We also ensured that the app was intuitive and easy to use for both doctors and patients, with a user-friendly interface and clear instructions.",
        "result": "The Advenium Tablet app was successfully developed and delivered to our client, providing hospitals with an efficient and user-friendly way to manage sessions, patients, and feedback. Doctors were able to seamlessly manage their schedules, mark attendance, leave notes, and review session information. Patients were able to provide feedback and answer questions in Kiosk mode, and doctors could view their responses on a graph sorted by date. The app provided a streamlined and secure experience for doctors and patients, improving the quality of patient care.",
        "startDate": "August 2021",
        "endDate": "Present",
        "industry": "Healthcare",
        "country": "USA",
        "shortDescription": "Advenium Tablet is a mobile app for hospitals with two modes, Provider and Kiosk. The app includes advanced search and filter functionality, and in Provider mode, doctors can manage sessions and patients, mark attendance, leave notes, and review session information. In Kiosk mode, patients can provide feedback and answer questions, and doctors can view their responses on a graph.",
        "description": "Advenium Tablet is a mobile app designed for hospitals that has two modes, Kiosk and Provider mode. The app integrates with a 3rd party device, a digital persona fingerprint reader, and includes advanced search and filter functionality. We had to write native code to integrate the fingerprint reader seamlessly into the app.\n\nIn Provider mode, doctors log in and can manage sessions and patients. After logging in, they can see a list of sessions for the current date, with advanced search and filter functionality. Doctors can select a session and view a list of patients on the left. They can mark who attended the session and who did not, add notes, and view session information on the right. Typically, session information consists of several parts that doctors must read to everyone.\n\nIn Kiosk mode, patients can answer a list of questions with a rating of 0 to 100. Doctors can view the answers to the questions on a graph, sorted by date.\n\nAdvenium Tablet provides hospitals with an efficient and user-friendly way to manage sessions, patients, and feedback. The app integrates seamlessly with a 3rd party device, providing a streamlined and efficient experience for doctors and patients.",
        "metaTags": {
            "title": "JSDaddy.io - Advenium Tablet Case Study",
            "description": "Learn how JSDaddy.io developed a comprehensive mobile app for hospitals, featuring Provider and Kiosk modes for managing sessions, patients, and feedback with advanced search and filter functionalities.",
            "keywords": "JSDaddy, Advenium Tablet, case study, mobile app, hospital management, session management, patient management, feedback system, healthcare solutions",
            "og:url": "https://jsdaddy.io/case-studies/advenium-tablet",
            "og:title": "JSDaddy.io - Advenium Tablet Case Study",
            "og:description": "Learn how JSDaddy.io developed a comprehensive mobile app for hospitals, featuring Provider and Kiosk modes for managing sessions, patients, and feedback with advanced search and filter functionalities."
        }
    },
    {
        "title": "Advenium Clinical",
        "slug": "advenium-clinical",
        "category": "Mobile Application",
        "assetsFolder": "adv-clinical",
        "type": "portrait-tablet",
        "androidLink": "https://play.google.com/store/apps/details?id=com.advenium.clinical",
        "solutions": [
            "Architecture",
            "Frontend Development"
        ],
        "images": [
            1,
            2,
            3,
            4,
            5
        ],
        "technologies": [
            "Flutter",
            "Dart",
            "Xcode",
            "Android Studio",
            "Firebase Crashlytics",
            "Firebase Distribution",
            "GetX",
            "GitHub Actions",
            "Notion"
        ],
        "challenge": "Our challenge was to develop a mobile application that enables doctors to load and complete patient forms, save them to a local database, and synchronize them with a server. We needed to create a user-friendly interface that would allow doctors to create and manage forms, as well as to implement advanced search and filter functionality for forms and patients. The main technical challenge was to handle conflicts when synchronizing data with the server and to aggregate large amounts of data in the local database.",
        "solution": "We designed a mobile app that was easy to use and provided doctors with a streamlined process for managing patient forms. The app allowed doctors to create new forms for patients, save them as drafts, and synchronize them with the server. The advanced search and filter functionality made it easy for doctors to find and manage patient forms. We implemented a local database (Hive) to save and manage large amounts of data, overcoming the main technical challenge. To handle conflicts during synchronization, we developed a conflict resolution mechanism that ensured data integrity.",
        "result": "The Advenium Clinical app was successfully developed and delivered to our client. Doctors were able to use the app to load and complete patient forms, saving them to a local database and synchronizing them with the server. The advanced search and filter functionality allowed doctors to easily find and manage patient forms, improving their productivity. The app's ability to handle conflicts during synchronization ensured data integrity, reducing errors and improving patient safety. Overall, the app provided doctors with a streamlined and efficient process for managing patient forms, improving the quality of patient care.",
        "startDate": "Septemper 2022",
        "endDate": "Present",
        "industry": "Healthcare",
        "country": "USA",
        "shortDescription": "Advenium Clinical is a mobile app designed for hospitals that allows doctors to load and complete patient forms, save them to a local database, and synchronize them with a server. The app also includes advanced search and filter functionality for forms and patients.",
        "description": "Advenium Clinical is a mobile app designed for hospitals that enables doctors to load and complete patient forms. To access the forms, doctors must authenticate through a PIN code or login and password. After logging in, doctors are directed to a screen displaying their patients' forms. Forms are loaded and saved to a local database (Hive), which was one of the main challenges of the project due to the need to aggregate large amounts of data.\n\nDoctors can create new forms for patients and save them as drafts, as well as synchronize completed forms with the server. If a form already exists on the server, the app handles conflicts and saves data based on how the conflicts are resolved. The app also includes advanced search and filter functionality for forms and patients.\n\nThe app consists of two tables: the first containing all forms, and the second displaying a list of patients. Doctors can open each patient's card and view their forms. Advenium Clinical provides doctors with a fast and convenient way to work with patient forms, saving data locally and synchronizing it with the server.",
        "metaTags": {
            "title": "JSDaddy.io - Advenium Clinical Case Study",
            "description": "Learn how JSDaddy.io developed Advenium Clinical, a mobile app for hospitals that allows doctors to manage patient forms, save them to a local database, and synchronize with a server.",
            "keywords": "JSDaddy, Advenium Clinical, case study, mobile app, hospital management, patient forms, local database, server synchronization",
            "og:url": "https://jsdaddy.io/case-studies/advenium-clinical",
            "og:title": "JSDaddy.io - Advenium Clinical Case Study",
            "og:description": "Learn how JSDaddy.io developed Advenium Clinical, a mobile app for hospitals that allows doctors to manage patient forms, save them to a local database, and synchronize with a server."
        }
    }
]
