<jsdaddy-header />
<div [class]="['content', readyForDiscussClass()].join(' ')">
    <router-outlet />
    <jsdaddy-footer [needCrankle]="needCrankle()!" />
</div>
<div
    #readyForDiscussContainer
    [style.visibility]="isShowReadyForDiscuss()! | visibility"
    [class]="
        [
            'ready-for-discuss',
            readyForDiscussClass(),
            isShowReadyForDiscuss()! | rightContent: innerWidth() || 0
        ].join(' ')
    ">
    <jsdaddy-ready-for-discuss />
    <div
        class="blur"
        [class.show]="isShowReadyForDiscuss()"
        [class.hide]="!isShowReadyForDiscuss()"></div>
</div>
