import { DestroyRef, inject, Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationSkipped, Router } from '@angular/router';
import { AppPath } from '@shared/routes/app-routing.enum';
import { BehaviorSubject, concatAll, distinct, filter, of, switchMap, tap, toArray } from 'rxjs';
import { IBreadcrumb } from './breadcrumbs.interface';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable()
export class BreadcrumbService {
    private readonly route = inject(ActivatedRoute);
    private readonly router = inject(Router);
    private readonly destroyRef = inject(DestroyRef);

    private breadcrubms$$ = new BehaviorSubject<IBreadcrumb[]>([]);

    public breadcrubms$ = this.breadcrubms$$.asObservable();

    public getBreadcrumbs(): void {
        this.router.events
            .pipe(
                filter(
                    (event): event is NavigationEnd =>
                        event instanceof NavigationEnd || event instanceof NavigationSkipped
                ),
                switchMap((event: NavigationEnd) => {
                    if (
                        [`/${AppPath.EMPTY}`, '/not-found'].includes(event.url)
                    ) {
                        return of([]);
                    }
                    return of([
                        { label: 'Home', url: '/' },
                        ...this.createBreadcrumbs(this.route),
                    ]).pipe(
                        concatAll(),
                        distinct((breadcrumb: IBreadcrumb) => breadcrumb.label),
                        toArray(),
                        filter((breadcrumbs: IBreadcrumb[]) => breadcrumbs.length > 1),
                        tap((breadcrumbs: IBreadcrumb[]) => {
                            this.breadcrubms$$.next(breadcrumbs);
                        })
                    );
                }),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe();
    }

    private createBreadcrumbs(
        route: ActivatedRoute,
        url = '',
        breadcrumbs: IBreadcrumb[] = []
    ): IBreadcrumb[] {
        const children: ActivatedRoute[] = route.children;

        if (children.length === 0) {
            return breadcrumbs;
        }

        for (const child of children) {
            const routeURL = child.snapshot.url.map((segment) => segment.path).join('/');

            const updatedURL = routeURL !== '' ? `${url}/${routeURL}` : url;
            const label: string | undefined =
                this.getCareerBreadcrubm(child.snapshot.params?.['career']) ??
                child.snapshot.params?.['service'] ??
                child.snapshot.params?.['case'] ??
                child.snapshot.data['breadcrumb'];

            if (label) {
                breadcrumbs.push({ label, url: updatedURL });
            }
            return this.createBreadcrumbs(child, updatedURL, breadcrumbs);
        }

        return [];
    }

    private getCareerBreadcrubm(career?: string) {
        if (!career) {
            return;
        }
        return career.replaceAll('-', ' ').slice(0, career.length - 1);
    }
}
