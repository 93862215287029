import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { RouterLinkWithHref } from '@angular/router';
import { ButtonComponent } from '@shared/button/button.component';
import { ButtonType } from '@shared/button/button.enums';
import { HeaderTitleComponent } from '@shared/header-title/header-title.component';
import { AppPath } from '@shared/routes/app-routing.enum';
import { IService } from '@content/home/services/services.interfaces';
import servicesJson from 'src/assets/json/services.json';

@Component({
    selector: 'jsdaddy-services',
    standalone: true,
    imports: [
        RouterLinkWithHref,
        ButtonComponent,
        NgTemplateOutlet,
        ButtonComponent,
        HeaderTitleComponent,
    ],
    templateUrl: './services.component.html',
    styleUrl: './services.component.scss',
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServicesComponent {
    public readonly ghostWhiteButton = ButtonType.GhostWhite;
    public readonly contactUsPath = `/${AppPath.CONTACT_US}`;
    public readonly servicesLink = `/${AppPath.SERVICES}`;
    public readonly services: IService[] = servicesJson;
}
