import { ChangeDetectionStrategy, Component, input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'jsdaddy-row-black-white-wrapper[title]',
    standalone: true,
    imports: [],
    templateUrl: './row-black-white-wrapper.component.html',
    styleUrl: './row-black-white-wrapper.component.scss',
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RowBlackWhiteWrapperComponent {
    public title = input.required<string>();
    public needBottomImg = input(false);
}
