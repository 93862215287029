<div class="steps row pos-rel">
    <ng-container
        *ngTemplateOutlet="challengeSolution; context: { $implicit: showCase() }"></ng-container>
</div>

<ng-template #challengeSolution let-showCase>
    <div class="column step-column">
        <div class="row">
            <div class="step w-50 column challenge">
                <span class="h3-text challenge-title step-title uppercase">challenge:</span>
                <span class="span">
                    {{ showCase?.challenge }}
                </span>
            </div>
            <ng-container
                *ngTemplateOutlet="
                    so;
                    context: { class: 'w-50 so-tablet hide-mobile right-steps' }
                "></ng-container>
        </div>
        <div class="row bottom-steps">
            <div class="column step solution w-50">
                <span class="h3-text solution-title step-title uppercase">solution:</span>
                <span class="span">
                    {{ showCase?.solution }}
                </span>
            </div>
            <div class="step column result pos-rel w-50 right-steps">
                <img
                    alt="Glasses"
                    class="hide-mobile hide-desktop"
                    width="1"
                    height="1"
                    [ngSrc]="'tablet-glasses' | asset: assetPath" />
                <img
                    alt="Glasses"
                    class="glasses-desktop hide-mobile hide-tablet"
                    width="1"
                    height="1"
                    [ngSrc]="'glasses' | asset: assetPathShared" />
                <span class="h3-text result-title step-title uppercase">result:</span>
                <span class="span">
                    {{ showCase?.result }}
                </span>
            </div>
        </div>
        <div class="hide-desktop hide-tablet row mobile-glasses-section">
            <img
                alt="Glasses"
                class="remove-left-mobile-wrapper-padding"
                width="1"
                height="1"
                [ngSrc]="'mobile-glasses' | asset: assetPath" />
            <ng-container
                *ngTemplateOutlet="
                    so;
                    context: { class: 'hide-desktop hide-tablet' }
                "></ng-container>
        </div>
    </div>
</ng-template>

<ng-template #so let-class="class">
    <div [class]="['step column', class].join(' ')">
        <p class="title so uppercase ls-25">So...</p>
        <span class="have-the-same uppercase h3-text"
            >have<br />
            the same<br />
            problem?</span
        >
        <jsdaddy-border-button title="Let's Talk!" [link]="contactUs" />
    </div>
</ng-template>
