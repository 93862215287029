<jsdaddy-form-sending-dialog />
<section
    [formGroup]="form"
    class="column"
    [id]="section?.id"
    jsdaddyCareerDetailsScroll
    (sectionChange)="onSectionChange($event)">
    <div class="content column pos-rel remove-tablet-wrapper-padding remove-mobile-wrapper-padding">
        <img
            class="background"
            [src]="'background' | asset: assetPathContactForm : true"
            alt="Background" />
        <span class="h2-text yellow">Tell us<span class="full-white"> about yourself</span></span>
        <span class="description text full-white"
            >Hey there! Thanks for your interest in jsdaddy! Fill out this application form.</span
        >
        <div class="inputs row jc-sb">
            @for (step of steps; track step.formControlName) {
                <jsdaddy-input
                    class="input"
                    [autoFocus]="false"
                    [formControlName]="step.formControlName"
                    [placeholder]="step.placeholder"
                    [validators]="step.validators" />
            }
        </div>
        <ngx-file-drop
            (onFileDrop)="dropped($event)"
            contentClassName="dnd-content"
            accept=".pdf,.doc,.docx"
            [multiple]="false"
            dropZoneClassName="dnd">
            <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                <span class="span full-white">Attach your CV, or DROP here</span>
                <div class="row ai-c bottom-drop">
                    <button class="browse bg-white black" (click)="openFileSelector()">
                        Browse
                    </button>
                    @if (fileAndError()) {
                        <div class="ml-14 row ai-c drop-title">
                            @if (fileAndError()?.file) {
                                <span class="text-opacity span"> File: </span>
                                <span class="ml-14 text-wrapper green span"
                                    ><span class="file-name">{{ fileAndError()?.file?.name }}</span>
                                    <img aria-hidden="true"
                                        (click)="clearFile()"
                                        class="pointer"
                                        [alt]="'img-close'"
                                        [ngSrc]="'close' | asset: assetPath"
                                        width="20"
                                        height="20"
                                /></span>
                            } @else {
                                @if (!fileAndError()?.fileError) {
                                    <span class="text-opacity span">no file selected</span>
                                }
                                @if (fileAndError()?.fileError) {
                                    <span class="text-wrapper title error-text span">{{
                                        fileAndError()?.fileError
                                    }}</span>
                                }
                            }
                        </div>
                    }
                </div>
            </ng-template>
        </ngx-file-drop>
        <jsdaddy-button class="btn" title="Apply" (click)="apply()" [type]="buttonType" />
    </div>
</section>
