<div class="breadcrumbs row uppercase title ls-25">
    @for (breadcrumb of breadcrumbs(); track breadcrumb.label; let l = $last) {
        @if (!l) {
            <a
                [attr.aria-label]="breadcrumb.label | anchorLabel"
                [routerLink]="breadcrumb.url"
                class="pointer breadcrumb"
                [class.white]="!isBlack()"
                [class.black]="isBlack()"
                >{{ breadcrumb.label }} >&nbsp;</a
            >
        }
        @if (l) {
            <span class="yellow"> {{ breadcrumb.label }}</span>
        }
    }
</div>
